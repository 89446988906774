import React, { useState, useEffect, useRef } from 'react';

import '../assets/styles/Header.css';

import icLogo from '../assets/images/icLogo.png';
import itaaLogo from '../assets/images/itaaLogo.png';
import Logo from '../assets/images/logoblue.png';

import { useRecoilState } from 'recoil';
import { navAtom, pathAtom, gstrpathAtom, redirectAtom, trnAtom, digStorageAtom, digErrorAtom, digMainErrorAtom, digDownloadAtom, bnameAtom, lmsAtom, userIDAtom, refreshAtom, loginAtom, isBankAtom } from '../core/config/atoms';

import { Dialog } from '@headlessui/react';
import socketIOClient from "socket.io-client";

import UIFooter from '../core/ui/navigation/UIFooter';
import UISlider from '../core/ui/navigation/UISlider';
import UIMenu from '../core/ui/navigation/UIMenu';

import { useIdleTimer } from 'react-idle-timer';

import PanelHome from '../panels/PanelHome';
import PanelLogout from '../panels/PanelLogout';
import PanelPage1 from '../panels/PanelPage1';
import PanelPage2 from '../panels/PanelPage2';
import PanelRegisterB from '../panels/PanelRegisterB';
import PanelRegister from '../panels/PanelRegister';
import PanelSuccessRegistration from '../panels/PanelSuccessRegistration';
import PanelTrnDashboard from '../panels/PanelTrnDashboard';
import PanelTrnForms from '../panels/PanelTrnForms';
import PanelLogin from '../panels/PanelLogin';
import PanelGSTRMain from '../panels/gstr1/PanelGSTRMain';
import PanelGSTRProfile from '../panels/gstr1/PanelGSTRProfile';
import PanelGSTReturn from '../panels/gstr1/PanelGSTReturn';
import PanelGSTRForm from '../panels/gstr1/PanelGSTRForm';
import PanelTrnDashboardA from '../panels/PanelTrnDashboardA';
import PanelTrnDashboardB from '../panels/PanelTrnDashboardB';

import PanelB2BHome from '../panels/gstr1/b2b/PanelB2BHome';
import PanelB2BForm from '../panels/gstr1/b2b/PanelB2BForm';

import PanelB2CLHome from '../panels/gstr1/b2cl/PanelB2CLHome';
import PanelB2CLForm from '../panels/gstr1/b2cl/PanelB2CLForm';

import PanelA6Home from '../panels/gstr1/a6/PanelA6Home';
import PanelA6Form from '../panels/gstr1/a6/PanelA6Form';

import PanelB2COHome from '../panels/gstr1/b2co/PanelB2COHome';
import PanelB2COForm from '../panels/gstr1/b2co/PanelB2COForm';

import PanelNillHome from '../panels/gstr1/nill/PanelNillHome';

import PanelCDRHome from '../panels/gstr1/cdr/PanelCDRHome';
import PanelCDRForm from '../panels/gstr1/cdr/PanelCDRForm';

import PanelCDUHome from '../panels/gstr1/cdu/PanelCDUHome';
import PanelCDUForm from '../panels/gstr1/cdu/PanelCDUForm';

import PanelA11Home from '../panels/gstr1/a11/PanelA11Home';
import PanelA11Form from '../panels/gstr1/a11/PanelA11Form';

import PanelB11Home from '../panels/gstr1/b11/PanelB11Home';
import PanelB11Form from '../panels/gstr1/b11/PanelB11Form';

import PanelA12Home from '../panels/gstr1/a12/PanelA12Home';
import PanelA13Home from '../panels/gstr1/a13/PanelA13Home';

import PanelAB2BHome from '../panels/gstr1/ab2b/PanelAB2BHome';
import PanelAB2BForm from '../panels/gstr1/ab2b/PanelAB2BForm';

import PanelAB2CLHome from '../panels/gstr1/ab2cl/PanelAB2CLHome';
import PanelAB2CLForm from '../panels/gstr1/ab2cl/PanelAB2CLForm';

import PanelAA6Home from '../panels/gstr1/aa6/PanelAA6Home';
import PanelAA6Form from '../panels/gstr1/aa6/PanelAA6Form';

import PanelACDRHome from '../panels/gstr1/acdr/PanelACDRHome';
import PanelACDRForm from '../panels/gstr1/acdr/PanelACDRForm';

import PanelACDUHome from '../panels/gstr1/acdu/PanelACDUHome';
import PanelACDUForm from '../panels/gstr1/acdu/PanelACDUForm';

import PanelAB2COHome from '../panels/gstr1/ab2co/PanelAB2COHome';
import PanelAB2COForm from '../panels/gstr1/ab2co/PanelAB2COForm';

import PanelAA11Home from '../panels/gstr1/aa11/PanelAA11Home';
import PanelAA11Form from '../panels/gstr1/aa11/PanelAA11Form';

import PanelAB11Home from '../panels/gstr1/ab11/PanelAB11Home';
import PanelAB11Form from '../panels/gstr1/ab11/PanelAB11Form';

import PanelGSTRSummry from '../panels/gstr1/PanelGSTRSummry';
import PanelFileValid from '../panels/gstr1/PanelFileValid';

import Panel2BHome from '../panels/gstr1/Panel2BHome';

import Panel3BHome from '../panels/gstr3b/Panel3BHome';

import Panel31Home from '../panels/gstr3b/tabs/Panel31Home';
import Panel311Home from '../panels/gstr3b/tabs/Panel311Home';
import Panel32Home from '../panels/gstr3b/tabs/Panel32Home';
import Panel4Home from '../panels/gstr3b/tabs/Panel4Home';
import Panel5Home from '../panels/gstr3b/tabs/Panel5Home';
import Panel51Home from '../panels/gstr3b/tabs/Panel51Home';
import Panel6AHome from '../panels/gstr3b/tabs/Panel6AHome';
import PanelVerifyHome from '../panels/gstr3b/tabs/PanelVerifyHome';

import PanelCreateChallan from '../panels/gstr3b/challan/PanelCreateChallan';
import PanelGenrateChallan from '../panels/gstr3b/challan/PanelGenrateChallan';
import PanelSuccessChallan from '../panels/gstr3b/challan/PanelSuccessChallan';
import PanelPaidChallan from '../panels/gstr3b/challan/PanelPaidChallan';
import PanelHistoryChallan from '../panels/gstr3b/challan/PanelHistoryChallan';

import PanelBankHome from '../panels/gstr3b/bank/PanelBankHome';
import PnaelBankLogin from '../panels/gstr3b/bank/PnaelBankLogin';

import DigRedirect from '../core/ui/dig/DigRedirect';
import DigStorage from '../core/ui/dig/DigStorage';
import DigError from '../core/ui/dig/DigError';
import DigMainError from '../core/ui/dig/DigMainError';
import DigDownload from '../core/ui/dig/DigDownload';

export default function PageHome() {

    const [nav, setNav] = useRecoilState(navAtom);
    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [redirect, setRedirect] = useRecoilState(redirectAtom);
    const [trnno, setTrnno] = useRecoilState(trnAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [digMainError, setDigMainError] = useRecoilState(digMainErrorAtom);
    const [digStorage, setDigStorage] = useRecoilState(digStorageAtom);
    const [digDownload, setDigDownload] = useRecoilState(digDownloadAtom);
    const [bname] = useRecoilState(bnameAtom);
    const [isLMS, setIsLMS] = useRecoilState(lmsAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [isRefresh, setRefresh] = useRecoilState(refreshAtom);
    const [isLogin, setIsLogin] = useRecoilState(loginAtom);
    const [isBank] = useRecoilState(isBankAtom);

    const [isNavOpen, setIsNavOpen] = useState(false);

    const socketIdRef = useRef("");

    const gotoPath = (path) => {
        setPath(path);
    }

    const logout = () => {
        setIsLogin(false);
        setNav('home');
        setTrnno('');
        setPath('logout');
    }

    const onActive = (e) => {
        setIsLMS(true);
    }

    const onIdle = (e) => {
        setRefresh(true);
        setIsLMS(false);
    }

    const idleTimer = useIdleTimer({ timeout: 1000 * 60 * 20, onIdle, onActive });

    useEffect(() => {
        //AMC: remove inside test keep in prod.
        const socket = socketIOClient("https://gstsim.itaaedu.com/");
        socket.on("connect", () => {
            let sid = socket.id
            socketIdRef.current = sid;
            let data = {
                "id": sid,
                "uid": userID
            }
            socket.emit("login", data);
        });
        socket.on("new", (data) => {
            if (data.uid == userID && data.id != socketIdRef.current) {
                socket.disconnect();
            }
        });
        socket.on('disconnect', () => {
            setRefresh(true);
            setIsLMS(false);
        });
    }, []);

    return (
        <>
            <div className='fixed w-full h-screen flex flex-col items-center justify-center opacity-10 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-1/2 mx-auto h-[340px] pointer-events-none' />
            </div>
            {
                !isBank && <header className="main-header">
                    <div className="skip">
                        <div className="container">
                            <div className="row">
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row branding">
                            <div className="col-xs-12" style={{ display: 'flex', alignItems: 'center' }}>
                                <a target="_blank" href="https://itaaedu.com/" title="ITAA GST Simulator Home">
                                    <img src={icLogo} className="logo" alt="ITAA GST Simulator Home" width="34px" height="36px" />
                                </a>
                                <h1 className="site-title"><a target="_blank" href="https://itaaedu.com/">Goods and Services Tax - ITAA Simulator</a></h1>
                                <div style={{ flexGrow: 1 }}></div>
                                <a target="_blank" href="https://itaaedu.com/" title="ITAA GST Simulator Home">
                                    <img src={itaaLogo} className="logo" alt="ITAA GST Simulator Home" width="100px" height="32px" />
                                </a>
                                <ul className="list-inline mlinks">
                                    {
                                        nav == 'home' && <li>
                                            <a onClick={() => {
                                                gotoPath('login');
                                            }}><i className="fa fa-sign-in"></i> Login</a>
                                        </li>
                                    }
                                    {
                                        nav == 'trn' && <li>
                                            <a onClick={() => {
                                                logout();
                                            }}><i class="fa fa-sign-out"></i> Logout</a>
                                        </li>
                                    }
                                    {
                                        nav == 'gstr' && <li>
                                            <div class={`dropdown ${isNavOpen ? 'open' : ''}`}>
                                                <a class="dropdown-toggle lang-dpwn" data-toggle="dropdown" aria-expanded="false" onClick={() => {
                                                    setIsNavOpen(!isNavOpen)
                                                }}>
                                                    <i class="fa fa-user"></i>
                                                    <span>{(bname.length > 20) ? bname.substr(0, 19) : bname}&nbsp;</span>
                                                </a>
                                                <ul class="dropdown-menu profile-dpdwn">
                                                    <li>
                                                        <p class="lst-log">Last Logged In at</p>
                                                        <p class="lst-log-dt">
                                                            <span>
                                                                xx:xx:xx&nbsp;
                                                            </span>
                                                            IST</p>
                                                    </li>
                                                    <li>
                                                        <a onClick={() => {
                                                            setIsNavOpen(false);
                                                            gotoPath('profile');
                                                        }}><i class="fa fa-user"></i> My Profile</a>
                                                    </li>
                                                    <li>
                                                        <a class="disabled" style={{ opacity: 0.7, pointerEvents: 'none' }}> <i class="fa fa-unlock-alt"></i> Change Password</a>
                                                    </li>
                                                    <li>
                                                        <a class="disabled" style={{ opacity: 0.7, pointerEvents: 'none' }}> <i class="fa fa-check-square-o"></i> Register / Update DSC</a>
                                                    </li>
                                                    <li>
                                                        <a onClick={() => {
                                                            setIsNavOpen(false);
                                                            logout();
                                                        }}><i class="fa fa-sign-out"></i> Logout</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    }
                                </ul>
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#main">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="sr-only">Toggle navigation</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </header>
            }
            <Dialog open={digMainError.isToggle} onClose={() => setDigMainError({ title: '', msg: '', isToggle: false })}>
                <DigMainError />
            </Dialog>
            <Dialog open={digError.isToggle} onClose={() => setDigError({ title: '', msg: '', isToggle: false })}>
                <DigError />
            </Dialog>
            <Dialog open={digStorage.isToggle} onClose={() => setDigStorage({ title: '', msg: '', isToggle: false })}>
                <DigStorage />
            </Dialog>
            <Dialog open={redirect.isToggle} onClose={() => setRedirect({ url: '', isToggle: false })}>
                <DigRedirect />
            </Dialog>
            <Dialog open={digDownload.isToggle} onClose={() => setDigDownload({ title: '', url: '', isToggle: false })}>
                <DigDownload />
            </Dialog>
            {!isBank && <UIMenu />}
            {(() => {
                switch (path) {
                    case 'home':
                        return (<PanelHome />);
                    case 'logout':
                        return (<PanelLogout />);
                    case 'demoPage1':
                        return (<PanelPage1 />);
                    case 'demoPage2':
                        return (<PanelPage2 />);
                    case 'registerb':
                        return (<PanelRegisterB />);
                    case 'login':
                        return (<PanelLogin />);
                    case 'register':
                        return (<PanelRegister />);
                    case 'successregister':
                        return (<PanelSuccessRegistration />);
                    case 'trnDashboard':
                        return (<PanelTrnDashboard />);
                    case 'trnDashboardA':
                        return (<PanelTrnDashboardA />);
                    case 'trnDashboardB':
                        return (<PanelTrnDashboardB />);
                    case 'trnForms':
                        return (<PanelTrnForms />);
                    case 'gstr':
                        return (<PanelGSTRMain />);
                    case 'profile':
                        return (<PanelGSTRProfile />);
                    case 'gstRetrun':
                        return (<PanelGSTReturn />);
                    case 'gstrForm':
                        return (<PanelGSTRForm />);
                    case 'gstrSummary':
                        return (<PanelGSTRSummry />);
                    case 'gstrFile':
                        switch (gstrPath) {
                            case 'b2ba':
                                return (<PanelB2BHome />);
                            case 'b2bb':
                                return (<PanelB2BForm />);
                            case 'b2cla':
                                return (<PanelB2CLHome />);
                            case 'b2clb':
                                return (<PanelB2CLForm />);
                            case 'a6a':
                                return (<PanelA6Home />);
                            case 'a6b':
                                return (<PanelA6Form />);
                            case 'b2coa':
                                return (<PanelB2COHome />);
                            case 'b2cob':
                                return (<PanelB2COForm />);
                            case 'nill':
                                return (<PanelNillHome />);
                            case 'cdra':
                                return (<PanelCDRHome />);
                            case 'cdrb':
                                return (<PanelCDRForm />);
                            case 'cdua':
                                return (<PanelCDUHome />);
                            case 'cdub':
                                return (<PanelCDUForm />);
                            case 'a11a':
                                return (<PanelA11Home />);
                            case 'a11b':
                                return (<PanelA11Form />);
                            case 'b11a':
                                return (<PanelB11Home />);
                            case 'b11b':
                                return (<PanelB11Form />);
                            case 'a12':
                                return (<PanelA12Home />);
                            case 'a13':
                                return (<PanelA13Home />);
                            case 'ab2ba':
                                return (<PanelAB2BHome />);
                            case 'ab2bb':
                                return (<PanelAB2BForm />);
                            case 'ab2cla':
                                return (<PanelAB2CLHome />);
                            case 'ab2clb':
                                return (<PanelAB2CLForm />);
                            case 'aa6a':
                                return (<PanelAA6Home />);
                            case 'aa6b':
                                return (<PanelAA6Form />);
                            case 'acdra':
                                return (<PanelACDRHome />);
                            case 'acdrb':
                                return (<PanelACDRForm />);
                            case 'acdua':
                                return (<PanelACDUHome />);
                            case 'acdub':
                                return (<PanelACDUForm />);
                            case 'ab2coa':
                                return (<PanelAB2COHome />);
                            case 'ab2cob':
                                return (<PanelAB2COForm />);
                            case 'aa11a':
                                return (<PanelAA11Home />);
                            case 'aa11b':
                                return (<PanelAA11Form />);
                            case 'ab11a':
                                return (<PanelAB11Home />);
                            case 'ab11b':
                                return (<PanelAB11Form />);
                        }
                    case 'gstr2BHome':
                        return (<Panel2BHome />);
                    case 'gstr3BForm':
                        return (<Panel3BHome />);
                    case 'gstr3B':
                        switch (gstrPath) {
                            case 'A3.1':
                                return (<Panel31Home />);
                            case 'A3.11':
                                return (<Panel311Home />);
                            case 'A3.2':
                                return (<Panel32Home />);
                            case 'A4':
                                return (<Panel4Home />);
                            case 'A5':
                                return (<Panel5Home />);
                            case 'A5.1':
                                return (<Panel51Home />);
                            case 'A6.1':
                                return (<Panel6AHome />);
                            case 'A7':
                                return (<PanelVerifyHome />);
                        }
                    case 'gstr3BChallan':
                        switch (gstrPath) {
                            case 'create':
                                return (<PanelCreateChallan />);
                            case 'genrate':
                                return (<PanelGenrateChallan />);
                            case 'success':
                                return (<PanelSuccessChallan />);
                            case 'view':
                                return (<PanelPaidChallan />);
                            case 'history':
                                return (<PanelHistoryChallan />);
                        }
                    case 'gstrBank':
                        switch (gstrPath) {
                            case 'login':
                                return (<PnaelBankLogin />);
                            case 'home':
                                return (<PanelBankHome />);
                        }
                    case 'gstrFileValid':
                        return (<PanelFileValid />);
                }
            })()}
            {
                !isBank && <div>
                    <UISlider />
                </div>
            }
            {
                !isBank && <footer>
                    <div class="ifooter " id="demo">
                        <UIFooter />
                    </div>
                </footer>
            }
            <div title="Scroll to Top" class="back-to-top">
                <i class="fa fa-angle-up"></i>
                <p>Top</p>
            </div>
        </>
    );
}
